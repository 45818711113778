import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "About me",
  "slug": "about-me",
  "cover": "./space.jpg",
  "screenshots": ""
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="/logo.png" width='240px' />
    <br />
    <br />
    <br />
    <p>{`I am Product Designer with a strong technical background on a neverending quest to make complicated things simple. I’ve been creating digital products for 7 years and during that time I have gone all the way from developing backends to inventing, designing and implementing user interfaces. I was lucky to have a chance to gain experience in the entire product creation process (wireframing, prototyping, designing, coding, user research). I understand and embrace importance of both business and customer perspective in the design process. I have a strong desire for learning new technologies and tools.`}</p>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Design skills:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`Web Design (8 years)`}</li>
      <li parentName="ul">{`UI Design (6 years)`}</li>
      <li parentName="ul">{`UX Design (6 years)`}</li>
      <li parentName="ul">{`Product Design (6 years)`}</li>
      <li parentName="ul">{`Design Systems (4 years)`}</li>
      <li parentName="ul">{`Prototyping (4 years)`}</li>
      <li parentName="ul">{`User Research (6 years)`}</li>
      <li parentName="ul">{`Branding (3 years)`}</li>
      <li parentName="ul">{`Logo Design (2 years)`}</li>
    </ul>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Programming languages:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`HTML5 (10 years)`}</li>
      <li parentName="ul">{`SCSS / LESS / CSS (10 years)`}</li>
      <li parentName="ul">{`Javascript (10 years)`}</li>
      <li parentName="ul">{`Ruby (5 years)`}</li>
    </ul>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Design tools:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`Sketch (4 years)`}</li>
      <li parentName="ul">{`Adobe Photoshop (4 years)`}</li>
      <li parentName="ul">{`Adobe Illustrator (4 years)`}</li>
      <li parentName="ul">{`Affinity Designer (2 years)`}</li>
      <li parentName="ul">{`Affinity Photo (2 years)`}</li>
      <li parentName="ul">{`Adobe After Effects (2 years)`}</li>
      <li parentName="ul">{`FramerJS (2 year)`}</li>
      <li parentName="ul">{`Origami (1 year)`}</li>
      <li parentName="ul">{`InVision (4 years)`}</li>
      <li parentName="ul">{`Hotjar (4 years)`}</li>
    </ul>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Dev technologies:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`React (2 years)`}</li>
      <li parentName="ul">{`AngularJS (3 years)`}</li>
      <li parentName="ul">{`Node.JS (3 years)`}</li>
      <li parentName="ul">{`Ruby on Rails (5 years)`}</li>
      <li parentName="ul">{`Middleman (3 years)`}</li>
      <li parentName="ul">{`Bootstrap (6 years)`}</li>
      <li parentName="ul">{`Git (10 years)`}</li>
    </ul>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Operating systems:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`Windows (10+ years)`}</li>
      <li parentName="ul">{`Mac OS X (4 years)`}</li>
      <li parentName="ul">{`Linux (5 years)`}</li>
    </ul>
    <br />
    <blockquote>
      <h4 parentName="blockquote">{`Favourite web tools:`}</h4>
    </blockquote>
    <ul>
      <li parentName="ul">{`Github`}</li>
      <li parentName="ul">{`Slack`}</li>
      <li parentName="ul">{`Codepen`}</li>
      <li parentName="ul">{`DigitalOcean`}</li>
      <li parentName="ul">{`Customer.io`}</li>
      <li parentName="ul">{`Mailchimp`}</li>
      <li parentName="ul">{`Intercom`}</li>
      <li parentName="ul">{`InVision`}</li>
      <li parentName="ul">{`Hotjar`}</li>
      <li parentName="ul">{`Google Analytics`}</li>
      <li parentName="ul">{`Zeplin`}</li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      